/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import dynamic from "next/dynamic";

const LoginForm = dynamic(() => import("components/Forms/LoginForm"), {
  ssr: false,
});

const LoginScreen = () => {
  return <LoginForm />;
};

export default LoginScreen;
